import i18n from "@/core/plugins/i18n";
const t = i18n.global.t;
// Self-employed
// Freelancer
// Retired
// Homemaker
// Part-time worker, Temporary worker, Contract employee
// Unemployed

export const occupations = [
  {
    label: t("fields.listedCompanyEmployee"),
    value: "listed-company-employee",
  },
  {
    label: t("fields.unlistedCompanyEmployee"),
    value: "unlisted-company-employee",
  },
  {
    label: t("fields.companyOfficer"),
    value: "company-officer",
  },
  {
    label: t("fields.privateOrganizationStaff"),
    value: "private-organization-staff",
  },
  {
    label: t("fields.teacher"),
    value: "teacher",
  },
  {
    label: t("fields.lawyerAccountantTaxAccountant"),
    value: "lawyer-accountant-tax-accountant",
  },
  {
    label: t("fields.doctor"),
    value: "doctor",
  },
  {
    label: t("fields.selfEmployed"),
    value: "self-employed",
  },
  {
    label: t("fields.freelancer"),
    value: "freelancer",
  },
  {
    label: t("fields.retired"),
    value: "retired",
  },
  {
    label: t("fields.houseWifeHouseHusband"),
    value: "housewife-househusband",
  },
  {
    label: t("fields.contractEmployee"),
    value: "contract-employee",
  },
  {
    label: t("fields.unemployed"),
    value: "unemployed",
  },
];
