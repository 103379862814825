import i18n from "@/core/plugins/i18n";
import { reactive } from "vue";
const t = i18n.global.t;

export enum accountTypes {
  margin = 0,
  cfdTrading = 1,
  productTrading = 2,
}

export enum accountRoles {
  standard = 0,
  alpha = 1,
}

export const investorDistinctionSelection = reactive([
  {
    label: t("fields.generalInvestor"),
    value: 0,
  },
  {
    label: t("fields.professionalInvestor"),
    value: 1,
  },
  {
    label: t("fields.specialInvestor"),
    value: 2,
  },
]);

export const accountRolesSelection = [
  {
    label: t("fields.standard"),
    value: accountRoles.standard,
  },
  {
    label: t("fields.alpha"),
    value: accountRoles.alpha,
  },
];

export const accountTypesSelection = [
  {
    label: t("fields.marginTrading"),
    value: accountTypes.margin,
  },
  {
    label: t("fields.cfdTrading"),
    value: accountTypes.cfdTrading,
  },
  {
    label: t("fields.productTrading"),
    value: accountTypes.productTrading,
  },
];
export const moneyRange = {
  range_1: {
    id: "range_1",
    label: t("type.jpMoneyRange.1"),
    value: "1",
  },
  range_2: {
    id: "range_2",
    label: t("type.jpMoneyRange.2"),
    value: "2",
  },
  range_3: {
    id: "range_3",
    label: t("type.jpMoneyRange.3"),
    value: "3",
  },
  range_4: {
    id: "range_4",
    label: t("type.jpMoneyRange.4"),
    value: "4",
  },
  range_5: {
    id: "range_5",
    label: t("type.jpMoneyRange.5"),
    value: "5",
  },
  range_6: {
    id: "range_6",
    label: t("type.jpMoneyRange.6"),
    value: "6",
  },
  range_7: {
    id: "range_7",
    label: t("type.jpMoneyRange.7"),
    value: "7",
  },
  range_8: {
    id: "range_8",
    label: t("type.jpMoneyRange.8"),
    value: "8",
  },
};

export const mainIncomeSources = [
  {
    label: t("fields.businessIncome"),
    value: 0,
  },
  {
    label: t("fields.realEstateIncome"),
    value: 1,
  },
  {
    label: t("fields.salaryIncome"),
    value: 2,
  },
  {
    label: t("fields.interestDividendIncome"),
    value: 3,
  },
  {
    label: t("fields.pensionIncome"),
    value: 4,
  },
  {
    label: t("fields.headOfHouseholdIncome"),
    value: 5,
  },
  {
    label: t("fields.none"),
    value: 6,
  },
  {
    label: t("fields.other"),
    value: 7,
  },
];

export const transactionMotives = [
  {
    label: t("fields.ourHomepage"),
    value: 0,
  },
  {
    label: t("fields.newsPaperMagazine"),
    value: 1,
  },
  {
    label: t("fields.takeOver"),
    value: 2,
  },
  {
    label: t("fields.requestForInformation"),
    value: 3,
  },
  {
    label: t("fields.webSearch"),
    value: 4,
  },
  {
    label: t("fields.introduction"),
    value: 5,
  },
  {
    label: t("fields.other"),
    value: 6,
  },
];

export const mainFunds = [
  {
    label: t("fields.surplusFunds"),
    value: 0,
  },
  {
    label: t("fields.determinedUseOfMoney"),
    value: 1,
  },
  {
    label: t("fields.borrowedMoney"),
    value: 2,
  },
  {
    label: t("fields.inheritedMoney"),
    value: 3,
  },
  {
    label: t("fields.retirementMoney"),
    value: 4,
  },
  {
    label: t("fields.pensionLivingExpenses"),
    value: 5,
  },
  {
    label: t("fields.other"),
    value: 6,
  },
];

export const experienceSelections = [
  {
    label: t("fields.none"),
    value: 0,
  },
  {
    label: t("fields.lessThanOneYear"),
    value: 1,
  },
  {
    label: t("fields.lessThanThreeYears"),
    value: 2,
  },
  {
    label: t("fields.lessThanFiveYears"),
    value: 3,
  },
  {
    label: t("fields.moreThanFiveYears"),
    value: 4,
  },
];

export const investmentPurposes = [
  {
    label: t("fields.profitOriented"),
    value: 0,
  },
  {
    label: t("fields.activeInvestment"),
    value: 1,
  },
  {
    label: t("fields.shortTermInvestment"),
    value: 2,
  },
  {
    label: t("fields.longTermInvestment"),
    value: 3,
  },
  {
    label: t("fields.swapProfit"),
    value: 4,
  },
  {
    label: t("fields.sellingProfit"),
    value: 5,
  },
  {
    label: t("fields.foreignCurrencyAssetHedge"),
    value: 6,
  },
];

export const assetManagementPeriodSelection = [
  {
    label: t("fields.shortTermOperation"),
    value: 0,
  },
  {
    label: t("fields.mediumTermOperation"),
    value: 1,
  },
  {
    label: t("fields.longTermOperation"),
    value: 2,
  },
  {
    label: t("fields.superLongTermOperation"),
    value: 3,
  },
];

export const financialInstiutionTypes = [
  {
    label: t("fields.bank"),
    value: 0,
  },
  {
    label: t("fields.shinkinBank"),
    value: 1,
  },
  {
    label: t("fields.creditUnion"),
    value: 2,
  },
  {
    label: t("fields.laborMoney"),
    value: 3,
  },
  {
    label: t("fields.laborUnion"),
    value: 4,
  },
  {
    label: t("fields.fisheriesCooperative"),
    value: 5,
  },
];

export const depositTypesSelection = [
  {
    label: t("fields.standardAccount"),
    value: 0,
  },
  {
    label: t("fields.checkingAccount"),
    value: 1,
  },
];
